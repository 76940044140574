import React from 'react';
// import Banner from '../../components/Shared/Banner/Banner';
// import Navigation from '../../components/Shared/Navigation/Navigation';
// import TransparentNav from '../../components/Shared/TransparentNav/TransparentNav';
import TransparentNavBanner from '../../components/Shared/TransparentNavBanner/TransparentNavBanner';
import Footer from '../../components/Shared/Footer/Footer';

// import servicesBanner from '../../assets/images/banner-images/services-banner.svg';
import servicesBanner from '../../assets/images/banner-images/jpg/jpgBanners/services-banner.jpg';
import Service from '../../components/PageComponents/Services/Service/Service';

import Reel from '../../components/Shared/Reel/Reel';
import Testimonial from '../../components/Shared/Testimonial/Testimonial';
import AnimatedFashion from '../../components/PageComponents/Home/AnimatedFashion/AnimatedFashion';

const ServicesPage = () => {
    return (
        <>
            {/* <TransparentNav /> */}
            {/* <Navigation /> */}
            {/* <Banner bannerImage={servicesBanner} bannerTitle="Services" /> */}
            <TransparentNavBanner bannerImage={servicesBanner} bannerTitle="Services" />
            <Service />
            <Reel page={"services"} />
            <AnimatedFashion />
            <Testimonial page={"services"} />
            <Footer />
        </>
    );
};

export default ServicesPage;