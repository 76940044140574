import fashion1 from '../assets/images/work-page-images/portfolio-images/categories/Fashion/fashion-01.jpg';
import fashion2 from '../assets/images/work-page-images/portfolio-images/categories/Fashion/fashion-02.jpg';
import fashion3 from '../assets/images/work-page-images/portfolio-images/categories/Fashion/fashion-03.jpg';
import fashion4 from '../assets/images/work-page-images/portfolio-images/categories/Fashion/fashion-04.jpg';
import fashion5 from '../assets/images/work-page-images/portfolio-images/categories/Fashion/fashion-05.jpg';
import fashion6 from '../assets/images/work-page-images/portfolio-images/categories/Fashion/fashion-06.jpg';
import fashion7 from '../assets/images/work-page-images/portfolio-images/categories/Fashion/fashion-07.jpg';
import fashion8 from '../assets/images/work-page-images/portfolio-images/categories/Fashion/fashion-08.jpg';
import fashion9 from '../assets/images/work-page-images/portfolio-images/categories/Fashion/fashion-09.jpg';

import top1 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-01.jpg';
import top2 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-02.jpg';
import top3 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-03.jpg';
import top4 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-04.jpg';
import top5 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-05.jpg';
import top6 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-06.jpg';
import top7 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-07.jpg';
import top8 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-08.jpg';
import top9 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-09.jpg';
import top10 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-10.jpg';
import top11 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-11.jpg';
import top12 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-12.jpg';
import top13 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-13.jpg';
import top14 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-14.jpg';
import top15 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-15.jpg';
import top16 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-16.jpg';
import top17 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-17.jpg';
import top18 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-18.jpg';
import top19 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-19.jpg';
// import top20 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-20.JPG';
// import top21 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-21.JPG';
// import top22 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-22.JPG';

// import top34 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-34.JPG';
// import top36 from '../assets/images/work-page-images/portfolio-images/categories/Top/top-36.JPG';

import bottom1 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-01.jpg';
import bottom2 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-02.jpg';
import bottom3 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-03.jpg';
import bottom4 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-04.jpg';
import bottom5 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-05.jpg';
import bottom6 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-06.jpg';
import bottom7 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-07.jpg';
import bottom8 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-08.jpg';
import bottom9 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-09.jpg';
import bottom10 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-10.jpg';
import bottom11 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-11.jpg';
import bottom12 from '../assets/images/work-page-images/portfolio-images/categories/Bottom/bottom-12.jpg';

export const portfolioCategories = [
    {
        id: 0,
        name: `all`,
        items: [
            {
                id: 34,
                img: top4,
                alt: `top4`,
            },
            {
                id: 36,
                img: top6,
                alt: `top6`,
            },
        ],
    },
    {
        id: 1,
        name: `fashion`,
        items: [
            {
                id: 1,
                img: fashion1,
                alt: `fashion1`,
            },
            {
                id: 2,
                img: fashion2,
                alt: `fashion2`,
            },
            {
                id: 3,
                img: fashion3,
                alt: `fashion3`,
            },
            {
                id: 4,
                img: fashion4,
                alt: `fashion4`,
            },
            {
                id: 5,
                img: fashion5,
                alt: `fashion5`,
            },
            {
                id: 6,
                img: fashion6,
                alt: `fashion6`,
            },
            {
                id: 7,
                img: fashion7,
                alt: `fashion7`,
            },
            {
                id: 8,
                img: fashion8,
                alt: `fashion8`,
            },
            {
                id: 9,
                img: fashion9,
                alt: `fashion9`,
            },
        ],
    },
    {
        id: 2,
        name: `top`,
        items: [
            {
                id: 1,
                img: top1,
                alt: `top1`,
            },
            {
                id: 2,
                img: top2,
                alt: `top2`,
            },
            {
                id: 3,
                img: top3,
                alt: `top3`,
            },
            {
                id: 4,
                img: top4,
                alt: `top4`,
            },
            {
                id: 5,
                img: top5,
                alt: `top5`,
            },
            {
                id: 6,
                img: top6,
                alt: `top6`,
            },
            {
                id: 7,
                img: top7,
                alt: `top7`,
            },
            {
                id: 8,
                img: top8,
                alt: `top8`,
            },
            {
                id: 9,
                img: top9,
                alt: `top9`,
            },
            {
                id: 10,
                img: top10,
                alt: `top10`,
            },
            {
                id: 11,
                img: top11,
                alt: `top11`,
            },
            {
                id: 12,
                img: top12,
                alt: `top12`,
            },
            {
                id: 13,
                img: top13,
                alt: `top13`,
            },
            {
                id: 14,
                img: top14,
                alt: `top14`,
            },
            {
                id: 15,
                img: top15,
                alt: `top15`,
            },
            {
                id: 16,
                img: top16,
                alt: `top16`,
            },
            {
                id: 17,
                img: top17,
                alt: `top17`,
            },
            {
                id: 18,
                img: top18,
                alt: `top18`,
            },
            {
                id: 19,
                img: top19,
                alt: `top19`,
            },
            // {
            //     id: 20,
            //     img: top20,
            //     alt: `top20`,
            // },
            // {
            //     id: 21,
            //     img: top21,
            //     alt: `top21`,
            // },
            // {
            //     id: 22,
            //     img: top22,
            //     alt: `top22`,
            // },
        ],
    },
    {
        id: 3,
        name: `bottom`,
        items: [
            {
                id: 1,
                img: bottom1,
                alt: `bottom1`,
            },
            {
                id: 2,
                img: bottom2,
                alt: `bottom2`,
            },
            {
                id: 3,
                img: bottom3,
                alt: `bottom3`,
            },
            {
                id: 4,
                img: bottom4,
                alt: `bottom4`,
            },
            {
                id: 5,
                img: bottom5,
                alt: `bottom5`,
            },
            {
                id: 6,
                img: bottom6,
                alt: `bottom6`,
            },
            {
                id: 7,
                img: bottom7,
                alt: `bottom7`,
            },
            {
                id: 8,
                img: bottom8,
                alt: `bottom8`,
            },
            {
                id: 9,
                img: bottom9,
                alt: `bottom9`,
            },
            {
                id: 10,
                img: bottom10,
                alt: `bottom10`,
            },
            {
                id: 11,
                img: bottom11,
                alt: `bottom11`,
            },
            {
                id: 12,
                img: bottom12,
                alt: `bottom12`,
            },
        ],
    },
];

// export const allFashion = [
//     {
//         id: 0,
//         img: portfolio1,
//         alt: `portfolio1`,
//     },
//     {
//         id: 1,
//         img: portfolio1,
//         alt: `portfolio1`,
//     },
//     {
//         id: 2,
//         img: portfolio2,
//         alt: `portfolio2`,
//     },
//     {
//         id: 3,
//         img: portfolio3,
//         alt: `portfolio3`,
//     },
//     {
//         id: 4,
//         img: portfolio4,
//         alt: `portfolio4`,
//     },
//     {
//         id: 5,
//         img: portfolio5,
//         alt: `portfolio5`,
//     },
//     {
//         id: 6,
//         img: portfolio6,
//         alt: `portfolio6`,
//     },
//     {
//         id: 7,
//         img: portfolio7,
//         alt: `portfolio7`,
//     },
//     {
//         id: 8,
//         img: portfolio8,
//         alt: `portfolio8`,
//     },
//     {
//         id: 9,
//         img: portfolio9,
//         alt: `portfolio9`,
//     },
//     {
//         id: 10,
//         img: portfolio10,
//         alt: `portfolio10`,
//     },
//     {
//         id: 11,
//         img: portfolio11,
//         alt: `portfolio11`,
//     },
//     {
//         id: 12,
//         img: portfolio12,
//         alt: `portfolio12`,
//     },
//     {
//         id: 13,
//         img: portfolio13,
//         alt: `portfolio13`,
//     },
// ];
