import React from 'react';
import logo from '../../../../src/assets/images/nav-images/nav-logo.svg';

import home from '../../../../src/assets/images/footer-images/icons/home-icon.svg';
import mail from '../../../../src/assets/images/footer-images/icons/mail-icon.svg';
import contact from '../../../../src/assets/images/footer-images/icons/contact-icon.svg';

import facebook from '../../../../src/assets/images/footer-images/social-icons/facebook.svg';
import pinterest from '../../../../src/assets/images/footer-images/social-icons/pinterest.svg';
import instagram from '../../../../src/assets/images/footer-images/social-icons/instagram.svg';
import linkedin from '../../../../src/assets/images/footer-images/social-icons/linkedin.svg';

import './Footer.css';

const Footer = () => {
    return (
        <footer>
            <div className="footer">
                <div className="container">
                    <div className="row gx-0">
                        <div className="col-md-4 col-xs-12 d-flex align-items-center justify-content-center">
                            <div className="logo-part">
                                <img
                                    src={logo}
                                    width="300"
                                    height="200"
                                    className="d-inline-block"
                                    alt="logo"
                                />
                            </div>
                        </div>

                        <div className="col-md-4 col-xs-12">
                            <div className="location-part">
                                <h4 className="text-uppercase fw-bold">Find Us</h4>
                                <div className="">
                                    <ul className="text-start">
                                        <li className="d-none justify-content-start align-items-center mb-2 mb-md-2">
                                            <img src={home} alt="home" />
                                            <span className="ms-3 ms-md-5"><span className="fw-bold">Tex Merchant PTE Limited :</span> 8 Marina View #39-04, Asia Square Tower 1, Singapore 018960</span>
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center mb-2 mb-md-2">
                                            <img src={home} alt="home" style={{ visibility: "visible", }} />
                                            <span className="ms-3 ms-md-5"><span className="fw-bold">Tex Merchant :</span> (Apartment: 4A) 5/4, K/1 Silicon Silver Dream, Mohonpur, Adabor, Dhaka-1207, Bangladesh.</span>
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center mb-4 mb-md-3">
                                            <img src={home} alt="home" style={{ visibility: "hidden", }} />
                                            <span className="ms-3 ms-md-5"><span className="fw-bold">TxM Lifestyle Limited :</span> 2/H/3, Golden Street, Ring Road, Shyamoli, Dhaka-1207, Bangladesh.</span>
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center mb-4 mb-md-3">
                                            <img src={mail} alt="mail" />
                                            <span className="ms-3 ms-md-5">info@texmerchantbd.com</span>
                                        </li>
                                        <li className="d-flex justify-content-start align-items-center">
                                            <img src={contact} alt="contact" />
                                            <span className="ms-3 ms-md-5">+8801778-470076</span>
                                        </li>
                                    </ul>
                                    {/* <ul className="col-md-10 second-items d-flex flex-column justify-content-around">
                                        <li>Corporate Office: 5/1, K/2 Mohonpur, Ring Road, Shaymoli, Adabor, Dhaka-1207.</li>
                                        <li>Head Office: (Apartment: 4A) 5/4, K/1 Silicon Silver Dream, Mohonpur, Adabor, Dhaka-1207.</li>
                                        <li>info@texmerchantbd.com</li>
                                        <li>+8801778-470076</li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-xs-12">
                            <div className="social-contact-links-part">
                                <h4 className="text-uppercase fw-bold">Follow us</h4>
                                <div className="d-flex justify-content-center justify-content-md-end align-items-center social-link">
                                    <a href="https://www.facebook.com/texmerchantbd" target="_blank" rel="noreferrer" style={{ fontSize: "18px", color: "#000", textDecoration: "none", }} >
                                        <img src={facebook} alt="facebook" />
                                    </a>
                                    <a href="https://www.pinterest.com/txmlifestyle/" target="_blank" rel="noreferrer" style={{ fontSize: "18px", color: "#000", textDecoration: "none", }} >
                                        <img className="ms-4 ms-md-3" src={pinterest} alt="pinterest" />
                                    </a>
                                    <a href="https://www.instagram.com/txmlifestyle/" target="_blank" rel="noreferrer" style={{ fontSize: "18px", color: "#000", textDecoration: "none", }} >
                                        <img className="mx-4 mx-md-3" src={instagram} alt="instagram" />
                                    </a>
                                    <a href="https://www.linkedin.com/company/texmerchant/" target="_blank" rel="noreferrer" style={{ fontSize: "18px", color: "#000", textDecoration: "none", }} >
                                        <img src={linkedin} alt="linkedin" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tiny-footer">
                    <div className="row gx-0">
                        <div className="col-12">
                            <p className="mb-0 py-2 py-md-2">Copyright © 2023. All rights reserved.</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;