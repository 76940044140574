export const navItems = [
    {
        id: 0,
        link: `/`,
        name: `Home`,
    },
    {
        id: 1,
        link: `/about`,
        name: `About`,
    },
    {
        id: 2,
        link: `/services`,
        name: `Services`,
    },
    {
        id: 3,
        link: `/works`,
        name: `Works`,
    },
    {
        id: 4,
        link: `/clients`,
        name: `Clients`,
    },
    {
        id: 5,
        link: `/contact`,
        name: `Contact`,
    },
    // {
    //     id: 6,
    //     link: `/booking`,
    //     name: `Book Online`,
    // },
    {
        id: 7,
        link: `/blogs`,
        name: `Blogs`,
    },
];