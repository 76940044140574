import React, { useEffect } from 'react';
import './AnimatedFashion.css';


const AnimatedFashion = () => {

  const letters = "Fashion"
  // Converts integer to hex 


  const colToHex = (c) => {
    // Hack so colors are bright enough
    let color = (c < 75) ? c + 75 : c
    let hex = color.toString(16);
    return hex.length === 1 ? "0" + hex : hex;
  }

  // uses colToHex to concatenate
  // a full 6 digit hex code
  const rgbToHex = (r, g, b) => {

    return "#" + colToHex(r) + colToHex(g) + colToHex(b);

  }

  // Returns three random 0-255 integers
  const getRandomColor = () => {
    return rgbToHex(
      Math.floor(Math.random() * 255),
      Math.floor(Math.random() * 255),
      Math.floor(Math.random() * 255));
  }



  const randomColor = () => {

    let html = ''
    for (let i = 0; i < letters.length; i++) {
      let color = getRandomColor();
      html +=
        "<span style=\"color:" + color + "\">" +
        letters[i] +
        "</span>";
    }
    document.getElementById("fashion").innerHTML = html

  };

  useEffect(() => {
    const interval = setInterval(() => {
      randomColor()
    }, 1000);
    return () => clearInterval(interval);
  });



  return (
    <section className="row gx-0 d-flex justify-content-center align-items-center my-4 mt-md-0 mb-md-5">
      <div className="col-12 d-flex justify-content-end align-items-center mt-1 my-md-0">
        <h1 id='fashion' className="rotate-text fashion-text">{""}</h1>
      </div>
    </section>
  );
};

export default AnimatedFashion;