import React from 'react';
// import Navigation from '../../components/Shared/Navigation/Navigation';
// import TransparentNav from '../../components/Shared/TransparentNav/TransparentNav';
// import Banner from '../../components/Shared/Banner/Banner';
import TransparentNavBanner from '../../components/Shared/TransparentNavBanner/TransparentNavBanner';
import Form from '../../components/PageComponents/Booking/BookingForm/Form';
import Footer from '../../components/Shared/Footer/Footer';

// import bookingBanner from '../../assets/images/banner-images/booking-banner.svg';
import bookingBanner from '../../assets/images/banner-images/png/pngBanners/book-online-banner.png';

const BookingPage = () => {
    return (
        <>
            {/* <TransparentNav /> */}
            {/* <Navigation /> */}
            {/* <Banner bannerImage={bookingBanner} bannerTitle="Book Online" /> */}
            <TransparentNavBanner bannerImage={bookingBanner} bannerTitle="Book Online" />
            <Form />
            <Footer />
        </>
    );
};

export default BookingPage;