import serviceItem0 from '../assets/images/services-page-images/service-items/service-items0.svg';
import serviceItem1 from '../assets/images/services-page-images/service-items/service-items1.svg';
import serviceItem2 from '../assets/images/services-page-images/service-items/service-items2.svg';
const useServices = () => {
    const serviceItems = [
        {
            id: 0,
            icon: serviceItem0,
            altertext: `serviceItem0`,
            title: `Product Development`,
            description: `Our product development service is designed to help our clients create and bring their ideas to life. We have a state-of-the-art R&D section with modern machines and experienced staff who are dedicated to developing products that meet our clients' specifications. We have developed over 3000 styles, and our team can work with you to create a customized product that meets your exact needs.`,
        },
        {
            id: 1,
            icon: serviceItem1,
            altertext: `serviceItem1`,
            title: `Sourcing and Manufacturing`,
            description: `Tex Merchant has been in the garment manufacturing industry for over 10 years and has established relationships with over 50 factories in Bangladesh, China, Pakistan, and India. We only work with factories that meet our strict ethical and quality standards, and we regularly conduct audits to ensure that our suppliers are in compliance. Our sourcing team can help you find the right factory for your specific needs, whether it's for a small run or a large production order.`,
        },
        {
            id: 2,
            icon: serviceItem2,
            altertext: `serviceItem2`,
            title: `Logistic Support`,
            description: `At Tex Merchant, we understand that getting your products delivered on time is critical to your business's success. That's why we have partnered with renowned logistics companies to offer our clients a comprehensive logistics service. We have a deep understanding of shipping terms and regulations, and our team can work with you to ensure that your products are delivered on time, every time.`,
        },
        {
            id: 3,
            icon: serviceItem0,
            altertext: `serviceItem0`,
            title: `Product Development`,
            description: `We have developed over 1000 of styles, Any of the idea of yours can be turned into reality in minimum possible time. Drop us an email, your product will be ready. It's free too.`,
        },
        {
            id: 4,
            icon: serviceItem1,
            altertext: `serviceItem1`,
            title: `Sourcing and Manufacturing`,
            description: `A wide range of Fabric mills and trims suppliers from Bangladesh, India, Pakistan and China, is our trusted suppliers. We are working with over 50 RMG factories in Bangladesh.`,
        },
        {
            id: 5,
            icon: serviceItem2,
            altertext: `serviceItem2`,
            title: `Logistic Support`,
            description: `Our partner Complete Shipping Limited is a renowned freight forwarder of the world Logistic support is nothing to worry about if you opt to choose our service. `,
        },
        {
            id: 6,
            icon: serviceItem0,
            altertext: `serviceItem0`,
            title: `Product Development`,
            description: `We have developed over 1000 of styles, Any of the idea of yours can be turned into reality in minimum possible time. Drop us an email, your product will be ready. It's free too.`,
        },
        {
            id: 7,
            icon: serviceItem1,
            altertext: `serviceItem1`,
            title: `Sourcing and Manufacturing`,
            description: `A wide range of Fabric mills and trims suppliers from Bangladesh, India, Pakistan and China, is our trusted suppliers. We are working with over 50 RMG factories in Bangladesh.`,
        },
        {
            id: 8,
            icon: serviceItem2,
            altertext: `serviceItem2`,
            title: `Logistic Support`,
            description: `Our partner Complete Shipping Limited is a renowned freight forwarder of the world Logistic support is nothing to worry about if you opt to choose our service. `,
        },
        {
            id: 9,
            icon: serviceItem0,
            altertext: `serviceItem0`,
            title: `Product Development`,
            description: `We have developed over 1000 of styles, Any of the idea of yours can be turned into reality in minimum possible time. Drop us an email, your product will be ready. It's free too.`,
        },
        {
            id: 10,
            icon: serviceItem1,
            altertext: `serviceItem1`,
            title: `Sourcing and Manufacturing`,
            description: `A wide range of Fabric mills and trims suppliers from Bangladesh, India, Pakistan and China, is our trusted suppliers. We are working with over 50 RMG factories in Bangladesh.`,
        },
        {
            id: 11,
            icon: serviceItem2,
            altertext: `serviceItem2`,
            title: `Logistic Support`,
            description: `Our partner Complete Shipping Limited is a renowned freight forwarder of the world Logistic support is nothing to worry about if you opt to choose our service. `,
        },
    ];
    return {
        serviceItems,
    };
};

export default useServices;