import React from 'react';
import { useNavigate } from 'react-router-dom';

import TransparentNav from '../TransparentNav/TransparentNav';

import './TransparentNavBanner.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, Pagination } from "swiper";
// import { clientsTestimonials } from '../../../Data/TestimonialData';

const TransparentNavBanner = ({ bannerImage, bannerTitle }) => {
    const navigate = useNavigate();

    // console.log(bannerTitle);

    const bannerStyle = {
        backgroundImage: `url('${process.env.PUBLIC_URL + bannerImage}')`,
        backgroundRepeat: "no-repeat",
        // width: "100%",
        // backgroundBlendMode: "overlay",
        // height: "300px",
        backgroundSize: "cover",
    }
    return (
        <section className={bannerTitle === "Home" ? "row gx-0 transparent-nav-banner transparent-nav-banner-home position-relative" : "row gx-0 transparent-nav-banner position-relative"} style={bannerStyle}>
            < TransparentNav />

            <div className={bannerTitle === "Home" ? "d-none" : "col-10 col-md-6 d-flex flex-column justify-content-start justify-content-md-start align-items-center"}>
                <div className="pb-2 pe-5" style={{ boxShadow: "0px 3px 0 var(--first-color)" }}>
                    <h2 className="fw-bold text-white banner-title mb-0 pb-0" style={{ boxShadow: "0px 3px 0 var(--first-color)" }}>{bannerTitle}</h2>
                </div>
            </div>
            {
                bannerTitle === "Home" &&

                <Swiper
                    spaceBetween={0}
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    // click={true}
                    modules={[Autoplay, Pagination]}
                    className="transparentNavBannerSwiper border-box position-absolute"
                    style={{ height: "350px", }}
                >
                    {
                        bannerImage?.map((item, index) =>
                            <SwiperSlide key={item?.id} className="my-0 py-5 py-md-5" style={{ backgroundImage: `url('${process.env.PUBLIC_URL + item?.image}')`, backgroundRepeat: "no-repeat", backgroundSize: "cover", }}>
                                <div className="home-banner-card pt-5 pt-md-5 mt-3 mt-md-3 ms-md-0">
                                    <h4 className="fw-bold d-md-none">{item?.title}</h4>
                                    <h2 className="fw-bold d-none d-md-flex">{item?.title}</h2>
                                    <p className="py-3 pt-md-3 pb-md-4" style={{ fontSize: window?.innerWidth > 769 ? "1rem" : "0.7rem" }}>
                                        {item?.description}
                                    </p>
                                    <button className="first-button rounded-pill px-3 py-1 px-md-3 py-md-2 custom-shadow2" onClick={() => navigate("booking")}>Get A Quote</button>
                                    <button className="first-button rounded-pill px-3 py-1 px-md-4 py-md-2 ms-3 ms-md-4 custom-shadow2" onClick={() => navigate("about")}>About Us</button>
                                </div>
                            </SwiperSlide>
                        )
                    }
                </Swiper>
            }
        </section>
    );
};

export default TransparentNavBanner;