import React from 'react';
// import TransparentNav from '../../components/Shared/TransparentNav/TransparentNav';
// import Navigation from '../../components/Shared/Navigation/Navigation';
import TransparentNavBanner from '../../components/Shared/TransparentNavBanner/TransparentNavBanner';
// import Banner from '../../components/Shared/Banner/Banner';
import PortfolioWorks from '../../components/Shared/PortfolioWorks/PortfolioWorks';
// import AboutFeatured from '../../components/PageComponents/About/AboutFeatured/AboutFeatured';
import WhyUs from '../../components/PageComponents/About/WhyUs/WhyUs';
import Service from '../../components/PageComponents/Services/Service/Service';
import Reel from '../../components/Shared/Reel/Reel';
import Testimonial from '../../components/Shared/Testimonial/Testimonial';
// import Clients from '../../components/Shared/Clients/Clients';
import TexPDF from '../../components/PageComponents/Home/TexPDF/TexPDF';
import FAQ from '../../components/Shared/FAQ/FAQ';
import Managements from '../../components/PageComponents/Home/Managements/Managements';
import Footer from '../../components/Shared/Footer/Footer';

// import homeBanner from '../../assets/images/banner-images/home-banner.svg';
// import homeBanner from '../../assets/images/banner-images/jpg/jpgBanners/home-banner.jpg';

import AnimatedFashion from '../../components/PageComponents/Home/AnimatedFashion/AnimatedFashion';

import { homeBannerSlidersData } from '../../Data/HomeBannerData';

const HomePage = () => {
    return (
        <>
            {/* <TransparentNav /> */}
            {/* <Navigation /> */}
            {/* <Banner bannerImage={homeBanner} bannerTitle="Home" /> */}
            <TransparentNavBanner bannerImage={homeBannerSlidersData} bannerTitle="Home" />
            <WhyUs />
            {/* <AboutFeatured page={"home"} /> */}
            <Service page={"home"} />
            <PortfolioWorks page={"home"} />
            <Reel page={"home"} />
            <AnimatedFashion />
            <Testimonial page={"home"} />
            <TexPDF />
            <FAQ />
            {/* <Clients page={"home"} /> */}
            <Managements />
            <Footer />
        </>
    );
};

export default HomePage;