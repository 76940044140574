
export const clientsTestimonials = [
    {
        id: 0,
        client: `"They are very good in communication and commitments."`,
        comments: `Jacob Thomas, USA.`,
    },
    {
        id: 1,
        client: `"Tex Merchant is good at prompt response which is one of major criteria in professionalism."`,
        comments: `Raul Shanel, Australia.`,
    },
    {
        id: 2,
        client: `"They quite good at their work 😊. It's been 8 years they are working for me and I am really happy to be with them."`,
        comments: `Oliver, Spain.`,
    },
];