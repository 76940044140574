import React from 'react';
import "./WhyUs.css";

import WhyUsBG from "../../../../assets/images/about-page-images/section-imgs/why-us-section-img.svg";

const whyUsBG = {
    color: "#fff",
    backgroundImage: `url(${window?.innerWidth > 769 ? WhyUsBG : WhyUsBG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    // borderRadius: "10%",
};

const WhyUs = () => {
    return (
        <section className="row gx-0 d-flex justify-content-center align-items-center why-us my-3 mb-5 my-md-5" style={whyUsBG}>
            <div className="col-11 my-3 my-md-5">
                <div className="row gx-0 d-flex flex-md-row justify-content-center align-items-center">
                    <div className="col-12 col-md-12">
                        <div className="section-title text-start text-md-start">
                            <h3>Why Us</h3>
                        </div>
                        <div className="mb-5 mb-md-0">
                            <p>
                                We turn Your Garment Manufacturing Experience into a pleasant journey by providing end to end support for free!
                            </p>
                            <p>
                                We serve buyers and importers to develop and manufacture readymade garments, and source everything for them, including shipment management.
                            </p>
                            <p>
                                Trusted by many leading Garment Importers and Global brands.
                            </p>
                            <p>
                                1. Free Product Development and Shipping – We produce samples for our Customer for free in our fully equipped R&D section and deliver them with express shipping with free of cost. We make our customer
                                product development cost zero regardless of the order commitment.
                            </p>
                            <p>
                                2. Wide Range of fabric to choose from - We have huge collection of fabric for our customer to choose from, with amazing prices and quality. We relate to the mills around the world. All you need is to tell us.
                                Things will be at your doorstep.
                            </p>
                            <p>
                                3. We are just not another buying office – We are your partner, we understand your need and your attachment with your goods, we understand every possible impact that makes into your business, so we are
                                always sincere about what we do. When things go wrong, we are the first one to step up.
                            </p>
                            <p>
                                4. We understand your budget- We provide you the best product that fits your budget without compromising anything, our huge supply chain network of over 500+ suppliers will help you to get your product in best price.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhyUs;