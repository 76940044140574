import React from 'react';
import { useNavigate } from 'react-router-dom';

const TexPDF = () => {
    const navigate = useNavigate();
    return (
        <section className="d-flex flex-column justify-content-center align-items-center mt-5 my-3 my-md-5">
            <button className="first-button rounded-pill fw-bold px-3 px-md-3 py-1 py-md-2 my-3 mt-md-0 mb-md-5 custom-shadow1" onClick={() => navigate("blogs")}>Visit Our Blogs</button>
            <iframe title="pdf" src={process.env.PUBLIC_URL + "/Assets/pdf/tex-merchant.pdf"} width="100%" height="550px"></iframe>
            <a
                href="/Assets/pdf/tex-merchant.pdf"
                download
                className="btn btn-sm rounded-pill fw-bold px-3 px-md-3 py-1 py-md-2 my-3 mt-md-5 mb-md-0 custom-shadow1"
                style={{ outline: "none", border: "none", opacity: 1, color: "var(--third-color)", backgroundColor: "var(--first-color)" }}
            >
                Download Our Profile
            </a>
        </section>
    );
};

export default TexPDF;