import React from 'react';
// import Navigation from '../../components/Shared/Navigation/Navigation';
// import TransparentNav from '../../components/Shared/TransparentNav/TransparentNav';
// import Banner from '../../components/Shared/Banner/Banner';
import Testimonial from '../../components/Shared/Testimonial/Testimonial';
import Footer from '../../components/Shared/Footer/Footer';

// import clientsBanner from '../../assets/images/banner-images/clients-banner.JPG';
import clientsBanner from '../../assets/images/banner-images/jpg/jpgBanners/client-banner.jpg';
import Clients from '../../components/Shared/Clients/Clients';
import TransparentNavBanner from '../../components/Shared/TransparentNavBanner/TransparentNavBanner';

const ClientsPage = () => {
    return (
        <>
            {/* <TransparentNav /> */}
            {/* <Navigation /> */}
            {/* <Banner bannerImage={clientsBanner} bannerTitle="Clients" /> */}
            <TransparentNavBanner bannerImage={clientsBanner} bannerTitle="Clients" />
            <Clients page={"clients"} />
            <Testimonial page={"clients"} />
            <Footer />
        </>
    );
};

export default ClientsPage;