import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './Location.css';

const Location = () => {
    return (
        <section>
            <Row className="gx-0 d-flex flex-column justify-content-center align-items-center">
                <Col xs={12} md={11} className="d-flex flex-column justify-content-center align-items-center text-center fw-bold rounded my-5 my-md-5 px-3 px-md-0 py-3 py-md-3" style={{ backgroundColor: "var(--first-color)", color: "var(--third-color)" }}>
                    <p className="mb-0">Looking for high-quality and cost-effective garment production and sourcing services? Look no further than Tex Merchant! With over 10 years of experience, we offer end-to-end solutions for your garment needs, from product development to logistics support. Our team of experts ensures timely delivery, efficient communication, and superior quality products.</p>
                    {/* <p className="mb-0">Or,</p> */}
                    <p className="mb-0 mt-4">Contact us today to experience the Tex Merchant difference!</p>
                </Col>
                <Col xs={12} md={6} className="d-flex justify-content-center align-items-center location py-5 py-md-5">
                    <h2 className="mb-0">We Are Here</h2>
                </Col>
                <Col xs={12} md={12} className="pb-5 pb-md-3">
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe
                                className="w-100"
                                height="570px"
                                title="g-map"
                                id="gmap_canvas"
                                src="https://maps.google.com/maps?q=5/A,%20TEX%20MERCHANT%20MNC.,%20k-1%20Mohonpur%20Rd,%20Dhaka%201207&t=&z=17&ie=UTF8&iwloc=&output=embed"
                            ></iframe>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    );
};

export default Location;