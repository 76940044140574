import React from 'react';
// import Navigation from '../../components/Shared/Navigation/Navigation';
// import Banner from '../../components/Shared/Banner/Banner';
// import TransparentNav from '../../components/Shared/TransparentNav/TransparentNav';
import TransparentNavBanner from '../../components/Shared/TransparentNavBanner/TransparentNavBanner';
import Footer from '../../components/Shared/Footer/Footer';

// import aboutBanner from '../../assets/images/banner-images/about-banner.svg';
import aboutBanner from '../../assets/images/banner-images/png/pngBanners/about-us-banner.png';
// import Reel from '../../components/Shared/Reel/Reel';
import Vision from '../../components/PageComponents/About/Vision/Vision';
import Mission from '../../components/PageComponents/About/Mission/Mission';
import WhyUs from '../../components/PageComponents/About/WhyUs/WhyUs';
import AboutFeatured from '../../components/PageComponents/About/AboutFeatured/AboutFeatured';

const AboutPage = () => {
    return (
        <>
            {/* <TransparentNav /> */}
            {/* <Navigation /> */}
            {/* <Banner bannerImage={aboutBanner} bannerTitle="About Us" /> */}
            <TransparentNavBanner bannerImage={aboutBanner} bannerTitle="About Us" />
            <Vision />
            <Mission />
            <WhyUs />
            <AboutFeatured page={"about"} />
            {/* <Reel /> */}
            <Footer />
        </>
    );
};

export default AboutPage;