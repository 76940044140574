import React from 'react';
// import Navigation from '../../components/Shared/Navigation/Navigation';
// import TransparentNav from '../../components/Shared/TransparentNav/TransparentNav';
// import Banner from '../../components/Shared/Banner/Banner';
import TransparentNavBanner from '../../components/Shared/TransparentNavBanner/TransparentNavBanner';
import PortfolioWorks from '../../components/Shared/PortfolioWorks/PortfolioWorks';
import Reel from '../../components/Shared/Reel/Reel';
import Testimonial from '../../components/Shared/Testimonial/Testimonial';
import Clients from '../../components/Shared/Clients/Clients';
import Footer from '../../components/Shared/Footer/Footer';

// import workBanner from '../../assets/images/banner-images/work-banner.svg';
import workBanner from '../../assets/images/banner-images/jpg/jpgBanners/our-work-banner.jpg';

const WorkPage = () => {
    return (
        <>
            {/* <TransparentNav /> */}
            {/* <Navigation /> */}
            {/* <Banner bannerImage={workBanner} bannerTitle="Our Works" /> */}
            <TransparentNavBanner bannerImage={workBanner} bannerTitle="Our Works" />
            <PortfolioWorks page={"work"} />
            <Reel page={"work"} />
            <Testimonial page={"work"} />
            <Clients page={"work"} />
            <Footer />
        </>
    );
};

export default WorkPage;