import React from 'react';
import { Col, Row } from 'react-bootstrap';
import useServices from '../../../../hooks/useServices';

import './Service.css';
import { useNavigate } from 'react-router-dom';

import serviceBG from "../../../../assets/images/about-page-images/section-imgs/services-section-img.png";

const servicesBG = {
    color: "#fff",
    backgroundImage: `url(${window?.innerWidth > 769 ? serviceBG : serviceBG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
};

const Service = ({ page }) => {
    const navigate = useNavigate();
    const { serviceItems } = useServices();
    return (
        <section className="py-5 py-md-5 mt-5 mt-md-5 service-page" style={servicesBG}>
            <Row className="gx-0 d-flex flex-column justify-content-center align-items-center">
                <Col xs={12} md={6} className="d-flex justify-content-center align-items-center services py-0 py-md-0">
                    <h2 className="mb-0">Services</h2>
                </Col>
                <Col xs={11} md={10} className="pb-0 py-md-0">
                    <Row xs={1} sm={2} md={3} className="gx-0 d-flex flex-row justify-content-center align-items-start">
                        {
                            page === "home" ?
                                serviceItems?.slice(0, 3)?.map((item, index) => (
                                    <Col key={item?.id} className="d-flex flex-column justify-content-center align-items-start py-3 py-md-3">
                                        <div className="py-2 py-md-3 ps-4 ps-md-4">
                                            <img className="" src={item?.icon} alt={item?.altertext} width="40" height="40" />
                                        </div>
                                        <div className="ps-3 ps-md-3" style={{ borderLeft: "5px solid var(--first-color)" }}>
                                            <h6 className="mb-0 mb-2 mb-md-3" style={{ color: "var(--first-color)" }}>{item?.title}</h6>
                                            <article className="pe-2 pe-md-3">{item?.description?.slice(0, 400)}</article>
                                        </div>
                                    </Col>
                                ))
                                :
                                serviceItems?.slice(0, 3)?.map((item, index) => (
                                    <Col key={item?.id} className="d-flex flex-column justify-content-center align-items-start py-3 py-md-3">
                                        <div className="py-2 py-md-3 ps-4 ps-md-4">
                                            <img className="" src={item?.icon} alt={item?.altertext} width="40" height="40" />
                                        </div>
                                        <div className="ps-3 ps-md-3" style={{ borderLeft: "5px solid var(--first-color)" }}>
                                            <h6 className="mb-0 mb-2 mb-md-3" style={{ color: "var(--first-color)" }}>{item?.title}</h6>
                                            <article className="pe-2 pe-md-3">{item?.description?.slice(0, 900)}</article>
                                        </div>
                                    </Col>
                                ))
                        }
                    </Row>
                    {
                        page === "home" &&
                        <div className="d-flex justify-content-center align-items-center mt-3">
                            <button className="first-button rounded-pill fw-bold px-3 px-md-3 py-1 py-md-2 custom-shadow2" onClick={() => navigate("services")}>View More</button>
                        </div>
                    }
                </Col>
            </Row>
        </section>
    );
};

export default Service;