import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BsArrowRight, } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

import './Clients.css';

import client0 from '../../../assets/images/clients-images/clients/client0.svg';
import client1 from '../../../assets/images/clients-images/clients/client1.svg';
import client2 from '../../../assets/images/clients-images/clients/client2.svg';
import client3 from '../../../assets/images/clients-images/clients/client3.svg';
import client4 from '../../../assets/images/clients-images/clients/client4.svg';
import client5 from '../../../assets/images/clients-images/clients/client5.svg';
import client6 from '../../../assets/images/clients-images/clients/client6.svg';
import client7 from '../../../assets/images/clients-images/clients/client7.svg';
import client8 from '../../../assets/images/clients-images/clients/client8.svg';
import client9 from '../../../assets/images/clients-images/clients/client9.svg';
import client10 from '../../../assets/images/clients-images/clients/client10.svg';
import client11 from '../../../assets/images/clients-images/clients/client11.svg';
import client12 from '../../../assets/images/clients-images/clients/client12.svg';
import client13 from '../../../assets/images/clients-images/clients/client13.svg';
import client14 from '../../../assets/images/clients-images/clients/client14.svg';

// import model0 from '../../../assets/images/clients-images/models/model0.svg';
import model0 from '../../../assets/images/clients-images/models/model0.png';
import model1 from '../../../assets/images/clients-images/models/model1.png';
import model2 from '../../../assets/images/clients-images/models/model2.png';

import modelBG from '../../../assets/images/clients-images/models/model-bg.svg';

const Clients = ({ page }) => {
    const navigate = useNavigate();

    // const models = [
    //     {
    //         id: 0,
    //         image: model0,
    //         alt: "model0",
    //     },
    //     {
    //         id: 1,
    //         image: model1,
    //         alt: "model1",
    //     },
    //     {
    //         id: 2,
    //         image: model2,
    //         alt: "model2",
    //     },
    // ];

    const clientsList = [
        {
            id: 0,
            image: client0,
            alterText: `client0`,
        },
        {
            id: 1,
            image: client1,
            alterText: `client1`,
        },
        {
            id: 2,
            image: client2,
            alterText: `client2`,
        },
        {
            id: 3,
            image: client3,
            alterText: `client3`,
        },
        {
            id: 4,
            image: client4,
            alterText: `client4`,
        },
        {
            id: 5,
            image: client5,
            alterText: `client5`,
        },
        {
            id: 6,
            image: client6,
            alterText: `client6`,
        },
        {
            id: 7,
            image: client7,
            alterText: `client7`,
        },
        {
            id: 8,
            image: client8,
            alterText: `client8`,
        },
        {
            id: 9,
            image: client9,
            alterText: `client9`,
        },
        {
            id: 10,
            image: client10,
            alterText: `client10`,
        },
        {
            id: 11,
            image: client11,
            alterText: `client11`,
        },
        {
            id: 12,
            image: client12,
            alterText: `client9`,
        },
        {
            id: 13,
            image: client13,
            alterText: `client10`,
        },
        {
            id: 14,
            image: client14,
            alterText: `client11`,
        },
    ];
    return (
        <section className="row gx-0 d-flex justify-content-center align-items-center my-0 my-md-0">
            {/* <div className="col-11 col-md-11 clients-section">
                <div className="row gx-0 d-flex justify-content-start align-items-end">
                    <div className="col-12 col-md-8">
                        <h6 className="text-uppercase fw-bold">Clients</h6>
                        <h3 className="text-capitalize">Working with the best</h3>
                    </div>
                </div>
            </div> */}
            <div className="col-11 col-md-11 mb-5 mb-md-5">
                <div className="row gx-0 d-flex justify-content-start align-items-end">
                    <div className="col-12 col-md-8">

                        <div className="row gx-0 d-flex justify-content-start align-items-between clients-section my-3 my-md-3">
                            <div className="col-12 col-md-8">
                                <h6 className="text-uppercase fw-bold">Clients</h6>
                                <h3 className="text-capitalize">Working with the best</h3>
                            </div>
                            <div className={page === "clients" ? "d-none" : "col-12 col-md-3 d-flex justify-content-end align-items-end"}>
                                <div className="">
                                    <span className="fs-6 fw-bold" onClick={() => navigate("/clients")} style={{ cursor: "pointer", }}>View More</span> <BsArrowRight className="mb-1" />
                                </div>
                            </div>
                        </div>


                        <Row xs={2} sm={2} md={2} className="gx-3 gx-md-3 gy-3 d-flex justify-content-start align-items-center">
                            {
                                clientsList?.slice(0, 6)?.map((item, index) =>
                                    <Col key={item?.id} className="d-flex justify-content-center justify-content-lg-start align-items-center">
                                        <div className="client d-flex justify-content-center align-items-center">
                                            <img className="" width="90%" height="99%" src={item?.image} alt={item?.alterText} />
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    </div>
                    <div className="col-12 col-md-4 ">
                        <Row xs={1} sm={1} md={1} className="gx-0 gy-3">
                            {
                                clientsList?.slice(0, 1)?.map((item, index) =>
                                    <Col key={item?.id} className="clients-model" style={{ width: "100%", height: "" }}>
                                        <div className="model-area h-100 d-flex justify-content-center align-items-center">
                                            <div className="model-bg h-75">
                                                <img className="h-100 w-100" width="" height="" src={modelBG} alt={modelBG} />
                                            </div>
                                            <div id="crossfade" className="model h-75">
                                                <img className="" width="" height="" src={model0} alt={model0} />
                                                <img className="" width="" height="" src={model1} alt={model0} />
                                                <img className="" width="" height="" src={model2} alt={model0} />
                                                {/* <img className="" width="" height="" src={model0} alt={model0} /> */}
                                            </div>
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    </div>
                </div>
                <div className={page !== "clients" ? "d-none" : "row gx-0 d-flex justify-content-center align-items-center mt-md-3"}>
                    <div className="col-12 col-md-8">
                        <Row xs={2} sm={2} md={2} className="gx-3 gx-md-3 gy-3 d-flex justify-content-start align-items-center">
                            {
                                clientsList?.slice(6, 12)?.map((item, index) =>
                                    <Col key={item?.id} className="d-flex justify-content-center justify-content-lg-start align-items-center">
                                        <div className="client d-flex justify-content-center align-items-center">
                                            <img className="" width="90%" height="99%" src={item?.image} alt={item?.alterText} />
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    </div>
                    <div className="col-12 col-md-4 mt-3 mt-md-0 ps-md-3 ps-xxl-0">
                        <Row xs={2} sm={2} md={1} className="gx-3 gx-md-3 gy-3 d-flex justify-content-start align-items-center">
                            {
                                clientsList?.slice(12, 15)?.map((item, index) =>
                                    <Col key={item?.id} className="d-flex justify-content-center justify-content-lg-start align-items-center">
                                        <div className="client d-flex justify-content-center align-items-center">
                                            <img className="" width="90%" height="99%" src={item?.image} alt={item?.alterText} />
                                        </div>
                                    </Col>
                                )
                            }
                        </Row>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default Clients;