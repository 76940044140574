import React from 'react';
import { clientsTestimonials } from '../../../Data/TestimonialData';

import qutation from '../../../assets/images/testimonial/qutation.svg';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import './Testimonial.css';


// import required modules
import { Autoplay, Pagination } from "swiper";

const Testimonial = ({ page }) => {
    return (
        <section className="row gx-0 d-flex flex-column justify-content-center align-items-center" style={{ margin: (page === "home" || page === "work") ? "30px 0" : "30px 0 100px 0", transform: "skewY(3deg)" }}>
            <div className="col-11 testimonialSwiperParent">
                <Swiper
                    spaceBetween={10}
                    centeredSlides={true}
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                    }}
                    // click={true}
                    modules={[Autoplay, Pagination]}
                    className="testimonialSwiper border-box testswipercss"
                >
                    {
                        clientsTestimonials?.map((item, index) =>
                            <SwiperSlide key={item?.id} className="my-3 py-md-5">
                                <div className="swiper-element d-flex flex-column justify-content-center align-items-center text-center py-3 py-md-5" style={{ transform: "skewY(-3deg)" }}>
                                    <img src={qutation} alt="qutation" className="pb-5" width="30" />
                                    <p className="py-3 py-md-3 px-2 px-md-0 fw-bold">{item?.comments}</p>
                                    <h6 className="fw-normal pb-5 pb-md-3">{item?.client}</h6>
                                </div>
                            </SwiperSlide>
                        )
                    }
                </Swiper>
            </div>

        </section>
    );
};

export default Testimonial;