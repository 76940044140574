import React from 'react';
import homeVideo from '../../../assets/images/video-reel/video/home-page-video.mp4';
import worksVideo from '../../../assets/images/video-reel/video/our-works-video.mp4';
import servicesVideo from '../../../assets/images/video-reel/video/services-video.mp4';

import './Reels.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Autoplay, } from "swiper";

const videos = [
    {
        id: 0,
        video: homeVideo,
    },
    {
        id: 1,
        video: worksVideo,
    },
    {
        id: 2,
        video: servicesVideo,
    },
]

const Reel = ({ page }) => {
    return (
        <section className="row g-0 my-3 my-md-0">
            <div className="col-12" style={{ position: "relative" }}>
                <div className="trapezium-video-container">
                    {
                        page &&

                        <Swiper
                            spaceBetween={0}
                            centeredSlides={true}
                            pagination={{
                                clickable: false,
                            }}
                            autoplay={{
                                delay: 3500,
                                disableOnInteraction: false,
                            }}
                            // click={false}
                            modules={[Autoplay]}
                            className="videoReelSwiper border-box"
                        >
                            {
                                videos?.map((item, index) =>
                                    <SwiperSlide key={item?.id} className="my-0">
                                        <video width="100%" height="" autoPlay={true} loop muted>
                                            <source src={item?.video} type="video/mp4" height={"70%"} width={"100%"} />
                                        </video>
                                    </SwiperSlide>
                                )
                            }
                        </Swiper>
                    }
                    {
                        // ((page === "work") || (page === "services")) &&
                        // <video width="100%" height="" autoPlay={true} loop muted>
                        //     <source src={page === "work" ? worksVideo : page === "services" ? servicesVideo : homeVideo} type="video/mp4" height={"70%"} width={"100%"} />
                        // </video>
                    }
                </div>
                {/* <div className="white-hide-overlay"></div> */}
            </div>
        </section>
    );
};

export default Reel;
