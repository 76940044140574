import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import './Form.css';

const Form = () => {
    const [inputValue, setInputValue] = useState({});

    const onChangeFunction = (e) => {
        // let data = { ...inputValue };
        // data[e.target.name] = e.target.value;
        // setInputValue(data);
        setInputValue(...inputValue, [e.target.name] = e.target.value);
    }


    const sendEmail = (e) => {
        e.preventDefault();

        if (window?.Email && inputValue?.user_text) {
            console.log(window?.Email);
            window.Email.send({

                SecureToken: `b6619a90-7cbd-4d47-8db7-e236cdba1a66`,
                To: 'takikauserbadhon@gmail.com',
                From: `takikauserbadhon@gmail.com`,
                Subject: inputValue?.subject + `, this is from : ` + inputValue?.user_email,
                Body: inputValue?.user_text,
            }).then(
                message => alert(`Email sent. We will contact with you soon.`)
            );
        } else {
            alert("Try Later!");
        }
    }

    return (
        <section>
            <Row className="gx-0 d-flex flex-column justify-content-center align-items-center">
                <Col xs={12} md={11} className="d-flex flex-column justify-content-center align-items-center text-center fw-bold my-5 my-md-5 px-3 px-md-0 py-3 py-md-3" style={{ backgroundColor: "var(--first-color)", color: "var(--third-color)" }}>
                    <p className="mb-0">Any query please fill up the fields and BOOK NOW today ! ! !</p>
                </Col>
                <Col xs={11} md={6} className="d-flex justify-content-center align-items-center location py-3 py-md-3">
                    <div className="contact-form w-100 mb-5">
                        <form onSubmit={sendEmail}>
                            <div className="d-flex flex-row flex-md-row mb-3">
                                <input
                                    name="first_name"
                                    className="contact-form-input me-2 mb-3 mb-md-0 me-md-3"
                                    type="text"
                                    required
                                    placeholder="First Name*"
                                    value={inputValue?.first_name}
                                    onChange={onChangeFunction}
                                />
                                <input
                                    name="last_email"
                                    required
                                    className="contact-form-input"
                                    type="text"
                                    placeholder="Last Name*"
                                    value={inputValue?.last_email}
                                    onChange={onChangeFunction}
                                />
                            </div>
                            <div className="d-flex flex-row flex-md-row mb-3">
                                <input
                                    name="user_email"
                                    required
                                    className="contact-form-input me-2 mb-3 mb-md-0 me-md-3"
                                    type="email"
                                    placeholder="E-mail*"
                                    value={inputValue?.user_email}
                                    onChange={onChangeFunction}
                                />
                                <input
                                    name="subject"
                                    className="contact-form-input"
                                    type="text"
                                    required
                                    placeholder="Subject*"
                                    value={inputValue?.subject}
                                    onChange={onChangeFunction}
                                />
                            </div>

                            <textarea
                                name="user_text"
                                className="contact-form-input"
                                type="text"
                                placeholder="Message*"
                                value={inputValue?.user_text}
                                onChange={onChangeFunction}
                            />
                            <button
                                value="send"
                                type="submit"
                                className="booking-button text-capitalized mt-3 custom-shadow1"
                            >
                                Book Now
                            </button>
                        </form>
                    </div>
                </Col>
            </Row>
        </section>
    );
};

export default Form;