import React from "react";

import blogsBanner from "../../assets/images/banner-images/jpg/jpgBanners/services-banner.jpg"; // need to change

// import Navigation from "../../components/Shared/Navigation/Navigation";
// import TransparentNav from "../../components/Shared/TransparentNav/TransparentNav";
// import Banner from "../../components/Shared/Banner/Banner";
import TransparentNavBanner from "../../components/Shared/TransparentNavBanner/TransparentNavBanner";
// import Footer from "../../components/Shared/Footer/Footer";

const BlogPage = () => {
    return (
        <>
            {/* <Navigation /> */}
            {/* <TransparentNav /> */}
            {/* <Banner bannerImage={blogsBanner} bannerTitle="Our Blogs" /> */}
            <TransparentNavBanner bannerImage={blogsBanner} bannerTitle="Our Blogs" />
            <div className="py-3">
                <iframe
                    title="blogs"
                    className="blogs-iframe"
                    src="https://widgets.sociablekit.com/linkedin-page-posts/iframe/193561"
                    frameBorder="0"
                    width="100%"
                    height="5000"
                    allow="autoplay"
                    allowFullScreen={true}
                    style={{
                        minHeight: "100vh",
                        minWidth: "100%",
                        // height: "100vh",
                        overflow: "hidden !important",
                        display: "block",
                    }}
                >

                </iframe>
            </div>
            {/* <Footer /> */}
        </>
    );
};

export default BlogPage;