import React from 'react';

import missionBackground from '../../../../assets/images/about-page-images/vision-background.svg';
import missionModel from '../../../../assets/images/about-page-images/mission-model.svg';
import missionOval from '../../../../assets/images/about-page-images/oval.svg';

const Mission = () => {
    return (
        <section className="row gx-0 d-flex justify-content-center align-items-center my-3 my-md-5">
            <div className="col-11">
                <div className="row gx-0 d-flex flex-row-reverse justify-content-center align-items-center">
                    <div className="col-12 col-md-9 px-2 px-md-3">
                        <div className="section-title text-center text-md-start">
                            <h3>Our Mission</h3>
                        </div>
                        <div className="">
                            <p>
                                At Tex Merchant, our mission is to be a reliable and trustworthy partner for our clients by delivering high-quality products at competitive prices, while ensuring ethical and sustainable practices in our operations. We strive to provide excellent customer service and build long-lasting relationships with our clients, suppliers, and partners.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 d-none d-md-flex justify-content-between align-items-center">
                        <div className=" d-flex flex-column justify-content-between align-items-center">
                            <img className="w-50 mb-5" src={missionBackground} alt="vision-background" />
                            <img className="w-75 mt-5" src={missionOval} alt="vision-oval" />
                        </div>
                        <div className="vision-model d-flex flex-column justify-content-between align-items-center" style={{}}>
                            <div className="box bounce d-flex justify-content-center align-items-center">
                                <img className="w-50 mb-5 mb-xxl-0" src={missionModel} alt="vision-model" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Mission;