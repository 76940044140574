import React from 'react';
import "./AboutFeatured.css";
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

// import AboutFeaturedBG from "../../../../assets/images/about-page-images/about-featured-bg-web.svg";
import AboutFeaturedBG from "../../../../assets/images/about-page-images/section-imgs/about-us-section-img-bg.png";
// import AboutFeaturedBGMobile from "../../../../assets/images/about-page-images/about-featured-bg-mobile.svg";
import { BsArrowRight } from 'react-icons/bs';

const aboutFeaturedBG = {
    color: "#fff",
    backgroundImage: `url(${window?.innerWidth > 769 ? AboutFeaturedBG : AboutFeaturedBG})`,
    backgroundRepeat: "no-repeat",
    // objectFit: "cover",
    backgroundPosition: 'center',
    // backgroundSize: `${(window?.innerWidth > 992) ? 'cover' : 'contain'}`,
    backgroundSize: 'cover',
    // width: "100% !important",
    // height: "100%",
    // blockSize: "100vw",
};
// const aboutFeaturedBGMobile = {
//     color: "#fff",
//     backgroundImage: `url(${AboutFeaturedBGMobile})`,
//     backgroundRepeat: "no-repeat",
//     backgroundSize: 'contain',
// };

const AboutFeatured = ({ page }) => {
    const navigate = useNavigate();

    return (
        <section className="py-5 py-md-5 my-3 my-md-5 about-featured-bg about-featured" style={aboutFeaturedBG}>
            <Row className="gx-0 d-flex flex-column justify-content-center align-items-center">
                <Col xs={12} md={6} className="d-flex justify-content-center align-items-center py-0 py-md-0">
                    <h3 className="mb-0">About Us</h3>
                </Col>
                <Col xs={11} md={10} className="pb-0 py-3 py-md-3">
                    <p className="mb-2 mb-md-2">
                        Tex Merchant is a multinational garment buying house that provides a range of services to clients across the globe. With over 10 years of experience in the industry, Tex Merchant has established itself as a trusted partner for clients looking to source high-quality garments at competitive prices.
                    </p>
                    <p className="d-flex d-md-flex mb-2 mb-md-2">
                        Headquartered in Bangladesh, Tex Merchant is registered in Singapore, allowing us to serve clients globally.
                    </p>
                    {
                        (page === "home" && window?.innerWidth <= 769) ?
                            <div className="col-12 col-md-12 d-flex justify-content-end align-items-end">
                                <div className="">
                                    <span className="fs-6 fw-bold" onClick={() => navigate("about")} style={{ cursor: "pointer", }}>View More</span> <BsArrowRight className="mb-0" />
                                </div>
                            </div>
                            :
                            <>
                                <p className="d-flex d-md-flex mb-2 mb-md-2">
                                    At Tex Merchant, we offer a comprehensive range of services to meet the needs of our clients. We specialize in almost every kind of garment product development and manufacturing. Our product development service is supported by our own R&D section with modern machines, allowing us to develop unique designs and create customized products. We have developed over 3000 styles, each designed to meet the specific needs of our clients.
                                </p>
                                <p className="d-flex d-md-flex">
                                    Our sourcing and manufacturing services are second to none, with a network of 50 factories as our suppliers and over 500 suppliers for raw materials from Bangladesh, India, China, and Pakistan. We work closely with our clients to ensure that we meet their quality standards and provide timely delivery of products.
                                </p>
                            </>
                    }
                </Col>
            </Row>
            {/* <div className="col-11">
                <div className="row gx-0 d-flex justify-content-end align-items-center py-md-5">
                    <div className="col-12 col-md-10 py-5 py-md-5">
                        <div className="py-md-2">
                            <h4 className="text-uppercase fw-bold" style={{ color: "var(--first-color)" }}>About Us</h4>
                        </div>
                        <div className="py-md-5">
                            <p className="mb-2 mb-md-2">
                                Tex Merchant is a multinational garment buying house that provides a range of services to clients across the globe. With over 10 years of experience in the industry, Tex Merchant has established itself as a trusted partner for clients looking to source high-quality garments at competitive prices.

                            </p>
                            <p className="d-flex d-md-flex mb-2 mb-md-2">
                                Headquartered in Bangladesh, Tex Merchant is registered in Singapore, allowing us to serve clients globally.
                            </p>
                            {
                                (page === "home" && window?.innerWidth <= 769) ?
                                    <div className="col-12 col-md-12 d-flex justify-content-end align-items-end">
                                        <div className="">
                                            <span className="fs-6 fw-bold" onClick={() => navigate("about")} style={{ cursor: "pointer", }}>View More</span> <BsArrowRight className="mb-0" />
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <p className="d-flex d-md-flex mb-2 mb-md-2">
                                            At Tex Merchant, we offer a comprehensive range of services to meet the needs of our clients. We specialize in almost every kind of garment product development and manufacturing. Our product development service is supported by our own R&D section with modern machines, allowing us to develop unique designs and create customized products. We have developed over 3000 styles, each designed to meet the specific needs of our clients.
                                        </p>
                                        <p className="d-flex d-md-flex">
                                            Our sourcing and manufacturing services are second to none, with a network of 50 factories as our suppliers and over 500 suppliers for raw materials from Bangladesh, India, China, and Pakistan. We work closely with our clients to ensure that we meet their quality standards and provide timely delivery of products.
                                        </p>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </div> */}
        </section>
    );
};

export default AboutFeatured;