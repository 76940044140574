import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import './App.css';
import AboutPage from "./Pages/AboutPage/AboutPage";
import BookingPage from "./Pages/BookingPage/BookingPage";
import ClientsPage from "./Pages/ClientsPage/ClientsPage";
import ContactPage from "./Pages/ContactPage/ContactPage";
import HomePage from "./Pages/HomePage/HomePage";
import ServicesPage from "./Pages/ServicesPage/ServicesPage";
import WorkPage from "./Pages/WorkPage/WorkPage";
import BlogPage from "./Pages/BlogPage/BlogPage";

import ScrollToTop from "./components/Shared/ScrollTop/ScrollToTop/ScrollToTop";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/services' element={<ServicesPage />} />
        <Route path='/works' element={<WorkPage />} />
        <Route path='/clients' element={<ClientsPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='/booking' element={<BookingPage />} />
        <Route path='/blogs' element={<BlogPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
