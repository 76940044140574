import React from 'react';

import visionBackground from '../../../../assets/images/about-page-images/vision-background.svg';
import visionModel from '../../../../assets/images/about-page-images/vision-model.svg';
import visionOval from '../../../../assets/images/about-page-images/oval.svg';

import './Vision.css';

const Vision = () => {
    return (
        <section className="row gx-0 d-flex justify-content-center align-items-center my-3 my-md-5">
            <div className="col-11">
                <div className="row gx-0 d-flex flex-md-row justify-content-center align-items-center">
                    <div className="col-12 col-md-9 px-2 px-md-3">
                        <div className="section-title text-center text-md-start">
                            <h3>Our Vision</h3>
                        </div>
                        <div className="">
                            <p>
                                Our vision at Tex Merchant is to be a leading global sourcing and supply chain management company in the textile industry. We aim to be recognized as a company that consistently delivers innovative, sustainable, and cost-effective solutions for our clients. We aspire to maintain our commitment to ethical business practices and to become a preferred choice for our clients and partners.
                            </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-3 d-none d-md-flex justify-content-center align-items-center mt-5">
                        <div className=" d-flex flex-column justify-content-between align-items-center">
                            <img className="w-50 mb-5" src={visionBackground} alt="vision-background" />
                            <img className="w-75 mt-5" src={visionOval} alt="vision-oval" />
                        </div>
                        <div className="vision-model d-flex flex-column justify-content-center align-items-center" style={{}}>
                            {/* <img className="w-50 mb-5" src={visionModel} alt="vision-model" /> */}
                            {/* <div className="stage"> */}
                            <div className="box bounce d-flex justify-content-center align-items-center">
                                <img className="w-50 mb-5 mb-xxl-0" src={visionModel} alt="vision-model" />
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Vision;