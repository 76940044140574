import React from 'react';
// import Navigation from '../../components/Shared/Navigation/Navigation';
// import TransparentNav from '../../components/Shared/TransparentNav/TransparentNav';
// import Banner from '../../components/Shared/Banner/Banner';
import TransparentNavBanner from '../../components/Shared/TransparentNavBanner/TransparentNavBanner';
import Footer from '../../components/Shared/Footer/Footer';

// import contactBanner from '../../assets/images/banner-images/contact-banner.svg';
import contactBanner from '../../assets/images/banner-images/jpg/jpgBanners/contact-us-banner.jpg';
import Location from '../../components/PageComponents/Contact/MapLocation/Location';

const ContactPage = () => {
    return (
        <>
            {/* <TransparentNav /> */}
            {/* <Navigation /> */}
            {/* <Banner bannerImage={contactBanner} bannerTitle="Contact Us" /> */}
            <TransparentNavBanner bannerImage={contactBanner} bannerTitle="Contact Us" />
            <Location />
            <Footer />
        </>
    );
};

export default ContactPage;