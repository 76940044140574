
export const faqsData = [
    {
        id: 1,
        title: `They are very good in communication and commitments.`,
        description: `Jacob Thomas, USA.`,
    },
    {
        id: 2,
        title: `Tex Merchant is good at prompt response which is one of major criteria in professionalism.`,
        description: `Raul Shanel, Australia.`,
    },
    {
        id: 3,
        title: `They quite good at their work 😊. It's been 8 years they are working for me and I am really happy to be with them.`,
        description: `Oliver, Spain.`,
    },
];