import React, { useState } from "react";
import { Col, Row, Carousel, Image } from 'react-bootstrap';

import { BsFacebook, BsLinkedin, } from "react-icons/bs";
import { AiFillInstagram, } from "react-icons/ai";
import { SiGooglescholar, } from "react-icons/si";

import member0 from "../../../../assets/images/managements-images/members/silhouette-member.png";

import "./Managements.css";

const Managements = () => {

    const [indicator, setIndicator] = useState(0);

    const managementMembersInfo = [
        {
            id: 0,
            name: `Munjarin Rahman`,
            designation: `Managing Director`,
            facebookLink: `https://www.facebook.com/munjarinrahman.anjohn`,
            // pinterestLink: `https://www.pinterest.com`,
            linkedinLink: `https://www.linkedin.com/in/munjarin/`,
            instagramLink: `https://instagram.com/munjarin/`,
            googleScholarLink: `https://scholar.google.com/citations?user=xRhMuR8AAAAJ&hl=en`,
            description: `Munjarin Rahman, the Group Managing Director of Tex Merchant, is a seasoned entrepreneur and textile engineer with a passion for sustainable business practices. With over a decade of experience in the industry, Munjarin is a driving force behind the company's success, having founded the company in 2014.

            Prior to founding Tex Merchant, Munjarin worked as a product developer for several well-known brands, gaining valuable experience and knowledge of the industry. He is also an academic researcher and author of various academic and non-academic articles, reflecting his deep interest in the textile industry.

            As a musician and artist, Munjarin brings a unique perspective to the business world. He values education and animal rights, and he strives to make a positive impact on the world through his work at Tex Merchant. His goal is to build the company into a legacy, driven by sustainable practices and green energy.

            `,
            src: member0,
            alt: 'member0',
            indicator: member0,
        },
        {
            id: 1,
            name: `Rawjat Biswas`,
            designation: `Director (Marketing)`,
            facebookLink: `https://www.facebook.com/dip.biswas`,
            linkedinLink: `https://www.linkedin.com/in/rawjat-biswas-6909445b/`,
            // pinterestLink: `https://www.pinterest.com`,
            instagramLink: `https://www.instagram.com/rawjat_biswas/`,
            description: `Rawjat Biswas, the Deputy Managing Director of Tex Merchant, is a skilled textile engineer with extensive experience in garment merchandising and supply chain management. He has a wealth of industry experience, having worked for some of the finest factories in Bangladesh, where he gained expertise in manufacturing process management and enterprise resource planning.
            Rawjat's exceptional leadership skills have contributed significantly to Tex Merchant's growth and success. He is an expert negotiator, and his knowledge of local business models and practices has been invaluable to the company. As a musician, Rawjat finds creative inspiration in his work and is always seeking to improve his team's performance.
            `,
            src: member0,
            alt: 'member1',
            indicator: member0,
        },
        {
            id: 2,
            name: `Md. Maruf Al Sayeed Mishuk`,
            designation: `Director (Operation)`,
            facebookLink: `https://www.facebook.com/marufalsayed.mishuk`,
            linkedinLink: `https://www.linkedin.com/in/maruf-mishuk-3b830a98/`,
            // pinterestLink: `https://www.pinterest.com`,
            instagramLink: ``,
            description: `Md. Maruf Al Sayeed Mishuk, the Chairman of Tex Merchant, is a technical mastermind with a wealth of expertise in pattern design, CAD markers, garment production, washing, and sustainable denim production. He is a skilled leader who is passionate about improving his team's performance and achieving success through collaboration.
            Maruf's expertise in trend analysis and technical features of garments has been instrumental in guiding many buyers towards making informed decisions. He is also involved in pricing and negotiation for order acquisition, ensuring that Tex Merchant's clients receive the best possible value for their money.
            `,
            src: member0,
            alt: 'member2',
            indicator: member0,
        },
    ];

    return (
        <section>
            <Row className="gx-0 d-flex flex-column justify-content-center align-items-center management">
                <Col xs={11} md={6} className="d-flex justify-content-center align-items-center py-0 py-md-3">
                    <h2 className="mb-0 highlited-title">Meet the Management</h2>
                </Col>
                <Col xs={11} md={11} className="py-5 pb-md-5 managements-carousel">
                    <Row className="gx-0 d-flex flex-row justify-content-center align-items-start" style={{ backgroundColor: "#F5F5F5" }}>
                        <Col xs={11} md={11} className="pt-md-5 pb-md-0">
                            <Row className="gx-0 d-flex flex-row justify-content-center align-items-start">

                                <div className="d-flex justify-content-evenly align-items-start management-team-indicator">
                                    {managementMembersInfo?.map((item, i) => (
                                        <div key={i} className="d-flex flex-column justify-content-center align-items-center align-items-md-center">
                                            <Image
                                                src={item?.indicator}
                                                style={{
                                                    // height: (indicator === i) ? '70px' : "60px",
                                                    // width: (indicator === i) ? '70px' : "60px",
                                                    transition: "transform .3s",
                                                    transform: (indicator === i) ? "scale(1.1)" : "scale(1)",
                                                    margin: `${((window.innerWidth < 792) && (item?.id === 1)) ? "40px 20px 10px 20px" : "10px 20px"}`,
                                                    borderRadius: '50%',
                                                    // border: (indicator === i) ? '4px solid var(--primary-color)' : '4px solid transparent',
                                                    boxShadow: (indicator === i) ? "0px 4px 8px 0px black inset" : "",
                                                    cursor: "pointer",
                                                    opacity: (indicator !== i) ? 0.3 : 1,
                                                }}
                                                onClick={() => setIndicator(i)}
                                            />
                                            <div className="px-2 d-flex flex-column justify-content-center align-items-center align-items-md-center">
                                                <h6 className="mb-0 text-center">{item?.name}</h6>
                                                <p className="mt-2 mt-md-2 text-center">{item?.designation}</p>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <Carousel activeIndex={indicator} onSelect={setIndicator}>
                                    {managementMembersInfo?.map((item, index) => (
                                        <Carousel.Item key={index}>
                                            <div className="row gx-0 d-flex justify-content-center align-items-center">
                                                <div className="col-12 col-md-7 text-md-start px-md-5">
                                                    <div className="row gx-0 d-flex justify-content-between align-items-start my-3">
                                                        <div className="col-12 col-md-6">
                                                            <h3 className="ps-md-3" style={{ color: "var(--secondary-color)", }}>{item?.name}</h3>
                                                            <h6 className="ps-md-3">{item?.designation}</h6>
                                                        </div>
                                                        <div className="col-12 col-md-6 d-flex justify-content-start justify-content-md-end align-items-center align-items-md-center members-social-link">
                                                            {
                                                                (item?.facebookLink?.length > 0) &&
                                                                <a href={item?.facebookLink} target="_blank" rel="noopener noreferrer" >
                                                                    <BsFacebook className="fs-2" style={{ cursor: "pointer" }} />
                                                                </a>
                                                            }
                                                            {
                                                                (item?.linkedinLink?.length > 0) &&
                                                                <a href={item?.linkedinLink} target="_blank" rel="noopener noreferrer" >
                                                                    <BsLinkedin className="fs-2 mx-3 mx-md-3" style={{ cursor: "pointer" }} />
                                                                </a>
                                                            }
                                                            {
                                                                (item?.instagramLink?.length > 0) &&
                                                                <a href={item?.instagramLink} target="_blank" rel="noopener noreferrer" >
                                                                    <AiFillInstagram className="fs-1" style={{ cursor: "pointer" }} />
                                                                </a>
                                                            }
                                                            {
                                                                (item?.googleScholarLink?.length > 0) &&
                                                                <a href={item?.googleScholarLink} target="_blank" rel="noopener noreferrer" >
                                                                    <SiGooglescholar className="fs-2 ms-3 ms-md-3" style={{ cursor: "pointer" }} />
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="row gx-0 d-flex justify-content-between align-items-center management-team-description">
                                                        <div className="col-12 col-md-12">
                                                            <p className="ps-md-3">{item?.description}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-none col-12 col-md-5 d-md-flex justify-content-center align-items-center">
                                                    <Image className="w-50" src={item?.src} alt={item?.alt} />
                                                </div>
                                            </div>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>

                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    );
};

export default Managements;