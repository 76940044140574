import React, { useState } from 'react';
import { portfolioCategories } from '../../../Data/PortfolioWorksData';
import { useNavigate } from 'react-router-dom';


const PortfolioWorks = ({ page }) => {
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState(portfolioCategories[0]);

    const fashionRealItems = [...portfolioCategories[1]?.items];
    const topRealItems = [...portfolioCategories[2]?.items];
    const bottomRealItems = [...portfolioCategories[3]?.items];

    const allRealItems = [...portfolioCategories[1]?.items, ...portfolioCategories[2]?.items, ...portfolioCategories[3]?.items];

    const shuffle = (array) => array.sort(() => Math.random() - 0.5);

    shuffle(allRealItems);

    const [fashionItems, setFashionItems] = useState(allRealItems?.slice(0, 9));


    const loadMore = () => {
        setFashionItems(allRealItems?.slice(0, (fashionItems.length + 10)));
    }

    // console.log(allRealItems);

    const lineStyle = {
        width: '60%',
        height: '3px',
        backgroundColor: 'var(--first-color)',
        marginLeft: '3%',
    };

    return (
        <section className="row gx-0 d-flex justify-content-center align-items-center my-3 my-md-5">
            <div className="col-11 col-md-11">
                <div className="row gx-0 d-flex justify-content-center justify-content-md-center align-items-center align-items-md-center">
                    <div className="col-12 col-md-6">
                        <div className="my-3 my-md-5">
                            <div className="d-flex justify-content-start align-items-center pb-2 pb-md-3">
                                <h3 className="mb-0" style={{ color: "var(--first-color)" }}>Portfolio</h3><div style={lineStyle}></div>
                            </div>
                            <h1 style={{ color: "var(--first-color)" }}>Our Beautiful Works</h1>
                        </div>
                        <div className="me-md-5 mt-md-5 d-flex flex-row flex-md-row justify-content-around align-items-start">
                            {
                                portfolioCategories?.map((category, index) =>
                                    <h5 key={index}
                                        onClick={() => {
                                            setSelectedCategory(category);
                                            page === "home" ?
                                                // setFashionItems(fashionRealItems?.slice(1, 5))
                                                category.name === "all" ?
                                                    setFashionItems(allRealItems?.slice(0, 9)) :
                                                    category.name === "fashion" ?
                                                        setFashionItems(fashionRealItems?.slice(0, 9)) :
                                                        // category.name === "session" ?
                                                        //     setFashionItems(fashionRealItems?.slice(6, 10).reverse()) :
                                                        //     category.name === "studio" ?
                                                        //         setFashionItems(fashionRealItems?.slice(4, 8)) :
                                                        category.name === "top" ?
                                                            setFashionItems(topRealItems?.slice(0, 9)) :
                                                            category.name === "bottom" ?
                                                                setFashionItems(bottomRealItems?.slice(0, 9)) :
                                                                setFashionItems(allRealItems?.slice(0, fashionItems.length))
                                                :
                                                page === "work" &&
                                                    // category.name === "all" ?
                                                    // setFashionItems(allRealItems) :
                                                    category.name === "fashion" ?
                                                    setFashionItems(fashionRealItems) :
                                                    // category.name === "session" ?
                                                    //     setFashionItems(fashionRealItems?.slice(2, 10).reverse()) :
                                                    //     category.name === "studio" ?
                                                    //         setFashionItems(fashionRealItems?.slice(2, 8)) :
                                                    category.name === "top" ?
                                                        setFashionItems(topRealItems) :
                                                        category.name === "bottom" ?
                                                            setFashionItems(bottomRealItems) :
                                                            category.name === "all" &&
                                                                (window?.innerWidth > 769) ?
                                                                setFashionItems(allRealItems) :
                                                                setFashionItems(allRealItems?.slice(0, 9))
                                            // :
                                            // setFashionItems(allRealItems?.slice(0, 9))
                                        }}
                                        className="text-center text-uppercase ps-3 mb-4 mb-md-5"
                                        style={(selectedCategory?.name === category?.name) ? { color: "var(--first-color)", borderLeft: "3px solid", cursor: "pointer", fontSize: "1.2em", fontWeight: "700", } : { color: "#000", borderLeft: "3px solid transparent", cursor: "pointer", fontSize: "1.2em", fontWeight: "700", }}>
                                        {category?.name}
                                    </h5>
                                )
                            }
                        </div>
                        <div className="d-none d-md-flex justify-content-start align-items-center">
                            {
                                selectedCategory &&
                                <h1 className="text-uppercase mb-md-3 mx-auto" style={{ color: "var(--second-color)", }}>{selectedCategory?.name}</h1>
                            }

                        </div>
                    </div>
                    <div className="col-6 d-md-none d-flex justify-content-center align-items-center">
                        {
                            selectedCategory &&
                            <h3 className="text-uppercase fw-bold" style={{ color: "var(--second-color)", fontStyle: "monospace" }}>{selectedCategory?.name}</h3>
                        }

                    </div>
                    <div className="col-6 col-md-6">
                        <div className="row g-2 mb-2 mb-md-2 ps-1">
                            <div className="col-12 col-md-6 d-none d-md-flex">
                                <img className="p-1 border rounded" width="100%" height="320" src={fashionItems[0]?.img} alt="portfolio-item" style={{ objectFit: "cover" }} />
                            </div>
                            <div className="col-12 col-md-6 d-none d-md-flex">
                                <img className="p-1 border rounded" width="100%" height="320" src={fashionItems[1]?.img} alt="portfolio-item" style={{ objectFit: "cover" }} />
                            </div>
                            <div className="col-12 col-md-12 d-md-none">
                                <img className="p-1 border rounded" width="100%" height="232" src={fashionItems[0]?.img} alt="portfolio-item" style={{ objectFit: "cover" }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row gx-0 d-flex justify-content-center align-items-center">
                    <div className="col-12 col-md-12">
                        <div className="row g-2">
                            {
                                fashionItems?.slice(2)?.map((item, index) =>
                                    <div key={index} className="col-6 col-md-3">
                                        <img className="p-1 border rounded shadow" width="100%" height="232" src={item?.img} alt="portfolio-item" style={{ objectFit: "cover" }} />
                                    </div>
                                )
                            }
                            {
                                page === "home" &&
                                <div className="col-6 col-md-3 d-flex justify-content-center align-items-center">
                                    <h6 onClick={() => navigate("works")} className="text-uppercase fw-bold ps-3" style={{ color: "var(--first-color)", borderLeft: "3px solid", cursor: "pointer", }}>{`Load More Works`}</h6>
                                </div>
                            }
                            {
                                (((page === "work") && (selectedCategory?.name === "all")) && (fashionItems.length <= allRealItems.length)) &&
                                <div className={(fashionItems.length >= allRealItems.length) ? "d-none" : "col-6 col-md-3 d-flex justify-content-center align-items-center"}>
                                    <h6 onClick={() => loadMore()} className="text-uppercase fw-bold ps-3" style={{ color: "var(--first-color)", borderLeft: "3px solid", cursor: "pointer", }}>{`Load More`}</h6>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section >
    );
};

export default PortfolioWorks;