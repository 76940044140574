
import homeBanner1 from '../assets/images/banner-images/HomeBanners/home-1.jpg';
import homeBanner2 from '../assets/images/banner-images/HomeBanners/home-2.jpg';
import homeBanner3 from '../assets/images/banner-images/HomeBanners/home-3.jpg';
import homeBanner4 from '../assets/images/banner-images/HomeBanners/home-4.jpg';
import homeBanner5 from '../assets/images/banner-images/HomeBanners/home-5.jpg';
import homeBanner6 from '../assets/images/banner-images/HomeBanners/home-6.jpg';

export const homeBannerSlidersData = [
    {
        id: 1,
        image: homeBanner1,
        alter_text: homeBanner1,
        title: `Your Partner in Bangladesh`,
        description: `We are of the leading garments Manufacturer and Buying House in Dhaka, Bangladesh. With a young and energetic team we are commitment deliver high quality services and and products to you at competitive price.`,
    },
    {
        id: 2,
        image: homeBanner2,
        alter_text: homeBanner2,
        title: `16 lines in our factories, 25+ affiliates`,
        description: `We are of the leading garments Manufacturer and Buying House in Dhaka, Bangladesh. With a young and energetic team we are commitment deliver high quality services and and products to you at competitive price.`,
    },
    {
        id: 3,
        image: homeBanner3,
        alter_text: homeBanner3,
        title: `24/7 in call availability for clients`,
        description: `We are of the leading garments Manufacturer and Buying House in Dhaka, Bangladesh. With a young and energetic team we are commitment deliver high quality services and and products to you at competitive price.`,
    },
    {
        id: 4,
        image: homeBanner4,
        alter_text: homeBanner4,
        title: `Serving 25+ Brands`,
        description: `We are of the leading garments Manufacturer and Buying House in Dhaka, Bangladesh. With a young and energetic team we are commitment deliver high quality services and and products to you at competitive price.`,
    },
    {
        id: 5,
        image: homeBanner5,
        alter_text: homeBanner5,
        title: `Serving the world from 3 countries`,
        description: `We are of the leading garments Manufacturer and Buying House in Dhaka, Bangladesh. With a young and energetic team we are commitment deliver high quality services and and products to you at competitive price.`,
    },
    {
        id: 6,
        image: homeBanner6,
        alter_text: homeBanner6,
        title: `Shipping over 3 million unit a Years`,
        description: `We are of the leading garments Manufacturer and Buying House in Dhaka, Bangladesh. With a young and energetic team we are commitment deliver high quality services and and products to you at competitive price.`,
    },
];