import React from 'react';
import { Col, Row } from 'react-bootstrap';

import './FAQ.css';
import CollapsibleAccordion from '../CollapsibleAccordion/CollapsibleAccordion';

const FAQ = () => {
    return (
        <section className="mt-5 my-3 my-md-5">
            <Row className="gx-0 d-flex flex-column justify-content-center align-items-center faq">
                <Col xs={11} md={6} className="d-flex justify-content-center align-items-center py-0 py-md-3">
                    <h2 className="mb-0 highlited-title">FAQ</h2>
                </Col>
                <Col xs={12} md={12} className="py-5 pb-md-3">
                    <Row className="gx-0 d-flex flex-row justify-content-center align-items-start" style={{ backgroundColor: "#F5F5F5" }}>
                        <Col xs={12} md={12} className="py-5 pt-md-5 pb-md-5">
                            <Row className="gx-0 d-flex flex-row justify-content-center align-items-start">
                                <CollapsibleAccordion />
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </section>
    );
};

export default FAQ;