import React from "react";
import "./WhatsappButton.css";
import { BsChatLeftTextFill, } from "react-icons/bs";

const WhatsappButton = () => {
    return (
        <div className="whatsappButton">
            <a href="https://wa.me/+8801778470076" target="_blank" rel="noreferrer">
                <div className="d-flex justify-content-center align-items-center btn custom-shadow1" style={{ backgroundColor: "#3b6afd", borderColor: "#3b6afd", color: "#fff", boxShadow: "" }}>
                    {/* <i className="fa fa-whatsapp" style={{ fontSize: "1.5rem" }}></i> */}
                    <BsChatLeftTextFill size={20} />
                    <h6 className="whatsappText fw-bold ps-2">{"Let's Chat!"}</h6>
                </div>
            </a>
        </div>
    );
};

export default WhatsappButton;