import React, { useContext, useState } from 'react';
import { Accordion, AccordionContext, Card, useAccordionButton } from 'react-bootstrap';
import { BiPlusMedical } from 'react-icons/bi';

import './CollapsibleAccordion.css';

import { faqsData } from '../../../Data/FaqData';

const ContextAwareToggle = ({ children, eventKey, callback, arrow, setArrow, }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <button
            type="button"
            className="px-1 px-md-2 py-1 py-md-2 my-1"
            style={{ backgroundColor: "var(--third-color)", color: isCurrentEventKey ? "var(--second-color)" : "var(--second-color)", border: "none", outLine: "none", width: "100%", textAlign: "start", borderBottom: "1px solid var(--first-color)", }}
            onClick={() => {
                console.log(eventKey);
                decoratedOnClick();
                setArrow(eventKey);
            }}
        >
            {children}
        </button>
    );
}

const CollapsibleAccordion = () => {
    const [arrow, setArrow] = useState(null);

    return (
        <Accordion defaultActiveKey="" className={""}>
            {
                (faqsData?.length) &&
                faqsData?.map((item, index) =>
                    <Card key={item?.id} className="my-0 py-0">
                        <Card.Header className="p-0" style={{ borderBottom: "none", }}>
                            <ContextAwareToggle eventKey={item?.id} arrow={arrow} setArrow={setArrow} className="position-relative">
                                <div className="d-flex">
                                    {/* <div className="">
                                <span className="fw-bold rounded-pill px-3 py-1" style={{ backgroundColor: "var(--primary-color)", }}>{"title?.length"}</span>
                            </div> */}
                                    <div className="ps-2 pe-5">
                                        <span className="fw-bold" style={{ color: "var(--second-color)", }}>{item?.title}</span>
                                        <span className="position-absolute" style={{ right: 10, top: 6, transform: (item?.id === arrow) ? "rotate(45deg)" : "rotate(-90deg)", transitionDuration: "0.9s", }}><BiPlusMedical fill={"var(--first-color)"} /></span>
                                    </div>
                                </div>
                            </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={item?.id}>
                            <Card.Body className="p-0 m-0" style={{ backgroundColor: "#fff", }}>

                                <div
                                    className={"d-flex justify-content-between align-items-center faq-accordion-item px-2 py-1"}
                                    style={{ color: "#000", }}>
                                    <div className="ps-2 text-dark d-flex flex-column">
                                        <span>{item?.description}</span>
                                    </div>
                                </div>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                )
            }
        </Accordion>
    );
};

export default CollapsibleAccordion;