import React, { useState } from "react";
import "./TransparentNav.css";
import { Nav, Navbar } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";

import { navItems } from "../../../Data/NavigationData";

import logo from "../../../../src/assets/images/nav-images/nav-logo.svg";

import WhatsappButton from "../WhatsappButton/WhatsappButton";

import Typical from 'react-typical';

const TransparentNav = () => {
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Function to toggle the menu
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className={`transparent-nav ${isMenuOpen ? "menu-open" : ""}`}>
            <WhatsappButton />
            <Navbar collapseOnSelect expand="lg" bg="" variant=""
                className="text-uppercase"
                style={{ backgroundColor: "transparent", color: "var(--third-color)" }}
            >
                <div className="d-flex align-items-center justify-content-between py-0 px-3 mx-0 content">
                    <NavLink to="/" className="">
                        <img
                            src={logo}
                            width={(window?.innerWidth > 769) ? "70" : ""}
                            height="40"
                            className="d-inline-block align-top"
                            alt="logo"
                        />
                    </NavLink>

                    <NavLink to="/" className="navbar-brand">
                        {/* Tex Merchant */}
                        <Typical
                            className="mb-0"
                            steps={[
                                "Tex Merchant",
                                3000,
                                "",
                                500,
                            ]}
                            loop={Infinity}
                        // wrapper="b"
                        />
                    </NavLink>
                    <Navbar.Toggle
                        aria-controls="responsive-navbar-nav"
                        onClick={toggleMenu}
                    >
                        <div className="toggler-icon top-bar"></div>
                        <div className="toggler-icon middle-bar"></div>
                        <div className="toggler-icon bottom-bar"></div>
                    </Navbar.Toggle>


                    <Navbar.Collapse id="responsive-navbar-nav" className="collapse-style">
                        <Nav className="ms-md-auto fw-bold p-0 px-md-2 rounded-pill pages-links">
                            {
                                navItems?.map((navItem, index) => (
                                    <NavLink key={navItem?.id} to={navItem?.link} className="d-flex flex-column flex-md-row justify-content-start justify-content-md-center align-items-start align-items-md-center link nav-link link-style-mobile">
                                        <span className="ms-0 mx-md-2">{navItem?.name}</span>
                                    </NavLink>
                                ))
                            }
                        </Nav>
                        <Nav className="d-flex flex-row justify-content-end justify-content-md-end align-items-start align-items-md-center rounded-pill mt-3 mt-md-0 px-2 py-0 pb-md-0 ms-md-3 me-md-4">
                            <button onClick={() => navigate("/booking")} className="first-button rounded-pill py-1 py-md-2 custom-shadow2">
                                <span className="ms-0 mx-md-2 px-1 px-md-0">{"Get a Quote"}</span>
                            </button>
                        </Nav>
                    </Navbar.Collapse>
                </div>
            </Navbar>
        </nav >
    );
};

export default TransparentNav;